import React from 'react'
import './contacts.css'
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const getContactsImage = graphql`
  {
    fluid: file(relativePath: { eq: "deal.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
    `

const Contacts = () => {
    const data = useStaticQuery(getContactsImage);
    const customeEvent = (data) => {
  
      if (typeof window !== "undefined") {
      window.gtag('event', 'click', data);
      
      }
    }
    
    return (
        <div className="contacts-wrap">

            <div className="container py-5">
                  <div className="row contacts-adjust">
                    <h1 className="mx-auto contacts-title">Let's discuss your Idea</h1>
                  </div>
                <div className="row" >
                 
                <a onClick={()=>customeEvent({
        'event_category': 'Contact_Us',
        'event_label': 'Call_Us'
      })} className="cbtn btn-1" href="tel:+916306396163"><h3>Call Us</h3></a>
                
                <a onClick={()=>customeEvent({
        'event_category': 'Contact_Us',
        'event_label': 'Mail_Us'
      })} href="mailto:hi@techvizon.com" className="cbtn btn-3"><h3>Drop an email</h3></a>
                
                </div>
                
                <div className="mil">
                <br/>
                <br/>
                <h2>Or, Simply fill the form below !</h2>
                </div>
                <div className="row">
                <div className="col-md-6 py-2 my-auto">
                <Img fluid={data.fluid.childImageSharp.fluid} />
                </div>
                <div className="col-md-6">
                <section className="get-in-touch">
   {/* <h1 className="title">Get in touch</h1> */}
   <form className="contact-form row"  action="https://formsubmit.co/techvizon@outlook.com"  method="POST"  >
      <div className="form-field col-lg-6">
      <input type="hidden" name="_subject" value="New submission!"/>
         <input id="name"  name="name" className="input-text js-input" type="text" required/>
         <label className="label" htmlFor="name">Name</label>
      </div>
      <div className="form-field col-lg-6 ">
         <input id="email" name="email" className="input-text js-input" type="email" required/>
         <label className="label" htmlFor="email">E-mail</label>
      </div>
      <div className="form-field col-lg-6 ">
         <input id="company" name="company" className="input-text js-input" type="text" required/>
         <label className="label" htmlFor="company">Company Name</label>
      </div>
       <div className="form-field col-lg-6 ">
         <input id="phone" name="phone" className="input-text js-input" type="text" required/>
         <label className="label" htmlFor="phone">Contact Number</label>
      </div>
      <div className="form-field col-lg-12">
         <input id="message" name="message" className="input-text js-input" type="text" required/>
         <label className="label" htmlFor="message">Message</label>
      </div>
      <small className="mx-auto">By submiting this form you are agreeing to our <Link to="/privacy-policy">privacy policy.</Link></small>
      <div className="form-field col-lg-12 text-center">
        
      <input type="hidden" name="_captcha" value="false"></input>
      <input type="hidden" name="_next" value="https://www.techvizon.com/thanks/"/>
         <input onClick={()=>customeEvent({
        'event_category': 'Contact_Us',
        'event_label': 'Form Submit'
      })} className="submit-btn" type="submit" value="Submit" />
      </div>
   </form>
</section>
                </div>
                </div>

                <div className="features-clean">
        <div className="container">
            <div className="intro">
                <h2 className="text-center">We are At...  </h2>
                
            </div>
            <div className="row features">
                <div className="col-sm-6 col-lg-4 item">
                    <h3 className="name">India (HQ)</h3>
                    <p className="description">Amrapali Zodiac, Sector 120, Noida, 201301, UP, India</p>
                    <br/>
                    <p className="description">Contact: (+91) 6306396163</p>
                    <p className="description">Mail: hi@techvizon.com</p>
                </div>
                <div className="col-sm-6 col-lg-4 item">
                    <h3 className="name">Noida</h3>
                    <p className="description">A-60, Sector 41, Noida,<br/> 201301, UP, India</p>
                    <br/>
                    <p className="description">Contact: (+91) 6306396163</p>
                    <p className="description">Mail: hi@techvizon.com</p>
                    
                </div>
                <div className="col-sm-6 col-lg-4 item">
                    <h3 className="name">Lucknow</h3>
                    <p className="description">Hampton Courts, Lucknow,<br/> 226012, UP, India</p>
                    <br/>
                    <p className="description">Contact: (+91) 8840187793</p>
                    <p className="description">Mail: hi@techvizon.com</p>
                </div>
                
            </div>
        </div>
    </div>
                

            </div>
           
        </div>
    )
}

export default Contacts
