import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contacts from "../components/contacts/contacts"
const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us | Techvizon" pathname="/contact" description="If you have an idea, let's discuss and transform your idea &amp; vision into reality." />
    <Contacts/>
  </Layout>
)

export default ContactPage
